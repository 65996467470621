import React from "react";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import HeroText from "./hero-text";

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "hero_00" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      className="hero__wrapper"
      css={css`
        position: relative;
        margin-bottom: 2rem;
        margin-top: 2.5rem;

        @media screen and (max-width: 768px) {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          margin-bottom: 1rem;
          margin-top: 0;
        }
      `}
    >
      <Img
        fluid={data.file.childImageSharp.fluid}
        css={css`
          @media screen and (min-width: 768px) {
            max-height: 85vh;
          }
        `}
      />
      <HeroText />
    </div>
  );
};

export default HeroImage;
