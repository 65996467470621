import React from "react";
import { css } from "@emotion/core";
// import { useIntl } from "gatsby-plugin-intl";

const textWrapper = css`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin-top: 2rem;
`;

const textStyles = css`
  text-align: center;
  color: #effffa;
  font-weight: 300;
`;

const titleStyle = css`
  font-size: 4rem;
  @media screen and (min-width: 768px) {
    font-size: 6rem;
  }
`;

const subtitleStyle = css`
  font-size: 2rem;
  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroText = () => {
  // const intl = useIntl();
  return (
    <div
      css={css`
        ${textWrapper}
      `}
    >
      <h1
        css={css`
          ${textStyles}
          ${titleStyle}
        `}
      >
        Hans Tännler
      </h1>
      <h3
        css={css`
          ${textStyles}
          ${subtitleStyle}
        `}
      >
        {/* {intl.formatMessage({ id: "hero" })} */}
        Lebenskünstler
      </h3>
    </div>
  );
};

export default HeroText;
