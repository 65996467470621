import React from "react";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

import ProjectListItem from "./project-list-item";

const ProjectList = () => {
  const data = useStaticQuery(graphql`
    query {
      koord: file(name: { eq: "koordinaten_00" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shining: file(name: { eq: "shining_stones_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salive: file(name: { eq: "salive_00" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salvitae: file(name: { eq: "sal_02" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      street: file(name: { eq: "zollikerstrasse_3" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vase: file(name: { eq: "vase01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      css={css`
        display: grid;
        grid-gap: 1rem;

        @media screen and (min-width: 768px) {
          grid-gap: 2rem;
          grid-template-columns: repeat(2, 1fr);
        }
      `}
    >
      <ProjectListItem
        thumbnail={data.koord.childImageSharp.fluid}
        title="Koordinaten"
        slug="koordinaten"
      />
      <ProjectListItem
        thumbnail={data.shining.childImageSharp.fluid}
        title="Shining Stones"
        slug="shining-stones"
      />
      <ProjectListItem
        thumbnail={data.salive.childImageSharp.fluid}
        title="sALIVE"
        slug="salive"
      />
      <ProjectListItem
        thumbnail={data.vase.childImageSharp.fluid}
        title='Vase Collection "Geheimnis"'
        slug="vase"
      />
      <ProjectListItem
        thumbnail={data.street.childImageSharp.fluid}
        title="Street Art Installation"
        slug="street-art-installation"
      />
      <div
        css={css`
          ${iFrameContainer}
        `}
      >
        <iframe
          src="https://www.youtube.com/embed/zkYcAocqcYw"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          allowFullScreen
          title="Hans Tännler - Lebenskünstler"
        />
      </div>
    </div>
  );
};

export default ProjectList;
const iFrameContainer = css`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
