import React from "react";
import SEO from "../components/seo";
import Layout from "../layout/site-layout";
import HeroImage from "../components/frontpage-image";
import ProjectList from "../components/project-list";

export default () => {
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <HeroImage />
        <ProjectList />
      </Layout>
    </>
  );
};
