import React from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import { Link } from "gatsby-plugin-intl";

const ProjectListItem = ({ thumbnail, title, slug }) => (
  <Link
    to={"/" + slug}
    css={css`
      ${projectLinkStyles}
    `}
  >
    <Img
      css={css`
        height: 30rem;
        @media screen and (min-width: 768px) {
          height: 380px;
        }
      `}
      fluid={thumbnail}
      objectFit="cover"
      objectPosition="50% 50%"
    />
    <p
      css={css`
        ${titleStyles}
      `}
    >
      {title}
    </p>
    <div
      css={css`
        ${projectBackgroundStyles}
      `}
    ></div>
  </Link>
);

export default ProjectListItem;

const projectLinkStyles = css`
  text-decoration: none;
  position: relative;

  @media screen and (min-width: 768px) {
    &:hover p {
      opacity: 1;
      left: 2rem;
    }
  }
`;

const projectBackgroundStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.2;
`;

const titleStyles = css`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: #fff;
  z-index: 2;
  font-size: 2.8rem;

  @media screen and (min-width: 768px) {
    font-size: 3.2rem;
    opacity: 0;
    left: 0rem;
    transition: opacity 0.3s ease, left 0.4s ease;
  }
`;
